import { Controller } from "@hotwired/stimulus"
import { post, destroy } from "@rails/request.js"

// Connects to data-controller="search-form"
export default class extends Controller {
  search(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      // this.element.requestSubmit()
      if (event.target.value == "") {
        $('#users').html('');
        return;
      }      
      const frame = document.getElementById('users');
      const user_type = event.target.dataset.userType;
      const host_id = event.target.dataset.hostId;
      frame.src = "/users?query=" + event.target.value + "&user_type=" + user_type;
      if (host_id != undefined) {
        frame.src += "&host_id=" + host_id;
      }
      frame.reload();
    }, 200)
  }

  goToElementDataset(event, element){
    let dataSet, i=0;
    let node = event.target;
    while (i < 10) {
      if(node.nodeName == element) {
        dataSet = node.dataset;
        break;
      }
      node = node.parentElement;
    }
    return dataSet;
  }

  search_products(event) {
    clearTimeout(this.productSearchTimeout)
    this.productSearchTimeout = setTimeout(() => {
      const frame = document.getElementById('products');
      let promotion_id;
      if($("#selected_promotion_id")) {
        promotion_id = $("#selected_promotion_id").val();
      }
      let promotion_type;
      if($("#selected_promotion_name")) {
        promotion_type = $("#selected_promotion_name").val();
      }
      const order_id = event.target.dataset.orderId;
      const search_type = event.target.dataset.searchType;
      let url = `/products?query=${event.target.value}&order_id=${order_id}&search_type=${search_type}`;
      if(promotion_id) {
        url = `${url}&promotion_id=${promotion_id}`;
      }
      if(promotion_type) {
        url = `${url}&promotion_type=${promotion_type}`;
      }
      frame.src = url;
      frame.reload();
    }, 200)
  }

  get_order_params(){
    let order = {};
    order["user_id"] = $("input[name='order[user_id]']").val();
    order["currency"] = $("input[name='order[currency]']").val();
    return order;
  }

  close_product_add_disable_popup(){
    if($("#disableAddProductPopupClose")){
      $("#disableAddProductPopupClose").click();
    }
  }

  async response_message(response){
    try {
      if(!response.ok){
        let body = await response.json;
        window.showNotification('error', body.body);
      }
    } catch (error) {
      console.log(response);
    }
  }

  get_order_items(product_id = "", item_id = "") {
    let list = document.getElementById("order-items-list");
    let data = [];
    if(list){
      let order_items = list.children;
      for (let item = 0; item < order_items.length; item++) {
        let child = order_items[item];
        let order_item = {};
        order_item["product_id"] = child.querySelector("[name='order[order_items][][product_id]']").value;
        order_item["order_item_id"] = child.querySelector("[name='order[order_items][][id]']").value;
        order_item["quantity"] = child.querySelector("[name='order[order_items][][quantity]']").value;
        order_item["special"] = child.querySelector("[name='order[order_items][][special]']").value || false;
        order_item["applied_promotion_id"] = child.querySelector("[name='order[order_items][][applied_promotion_id]']").value || "";
        order_item["applied_promotion_name"] = child.querySelector("[name='order[order_items][][applied_promotion_name]']").value;
        if(product_id !== order_item["product_id"] || (item_id && item_id !== order_item["order_item_id"])){
          data.push(order_item);
        }
      }
    }
    return data;
  }

  showGuestCheckout(){
    if($("#guestCheckout")){
      console.log("sss", $("#guestCheckout"));
      console.log("ffff", $("#guest-order-final-total-value").val(), $("#guest-order-final-total-value"));
      if($("#guest-order-final-total-value") && parseFloat($("#guest-order-final-total-value").val()) > 0){
        $("#guestCheckout").show();
      }
    }
  }

  async add_product_item(dataset, quantity){
    const order_id = dataset.orderId;
    const product_id = dataset.productId;
    const order_type = dataset.orderType;
    let promotion_id;
    let promotion_type;
    if($("#selected_promotion_id")) {
      promotion_id = $("#selected_promotion_id").val();
      promotion_type = $("#selected_promotion_name").val();
    }
    let party_id, user_id;
    if($("#products-wrapper").data("party-id")){
      party_id = $("#products-wrapper").data("party-id");
      user_id = $("#products-wrapper").data("user-id");
    }
    else {
      party_id = $("#host-product-search").data("party-id");
      user_id = $("#host-product-search").data("user-id");
    }
    let url = `/parties/${party_id}${order_type == "host" ? "/host" : ""}/orders/update_order_items?user_id=${user_id}`;
    if(promotion_id) {
      url = `${url}&promotion_id=${promotion_id}`;
    }
    const order = this.get_order_params();
    let order_items = this.get_order_items();
    let order_item = order_items.find((item) => item.product_id.toString() == product_id.toString() && item.applied_promotion_name == (promotion_type || ""));
    if(!order_item){
      order_item = { product_id, quantity };
      if(promotion_id) {
        order_item["special"] = true;
        order_item["applied_promotion_name"] = promotion_type;
      }
      order_items.push(order_item);
    } else{
      order_item["quantity"] = quantity;
    }
    let response = await post(url, { responseKind: "turbo-stream", body: { order: { ...order, order_items }, order_id, promotion_id} });
    $("#product-search-filter").val("");
    if(response.ok){
      setTimeout(this.showGuestCheckout, 500);
      window.showNotification('success', window.order_item_added_success_message);
    } else {
      this.response_message(response);
    }
    setTimeout(this.close_product_add_disable_popup, 500);
  }

  async add_popup_product(event){
    let dataset = this.goToElementDataset(event, "BUTTON");
    const quantity = $("#product-detail-quantity").val();
    await this.add_product_item(dataset, quantity);
    if($("#productDetailsClose")){
      $("#productDetailsClose").click();
    }
  }

  async add_product(event) {
    let dataset = this.goToElementDataset(event, "BUTTON");
    const product_id = dataset.productId;
    const quantity = $(`#product-quantity-${product_id}`).val();
    await this.add_product_item(dataset, quantity);
  }

  async add_host_product_item(dataset, quantity){
    const order_id = dataset.orderId;
    const product_id = dataset.productId;
    const currency = dataset.currency;
    const special = dataset.special;
    const promotion_id = dataset.promotionId || "";
    const promotion_name = dataset.promotionName || "";
    let party_id, user_id;
    if($("#products-wrapper").data("party-id")){
      party_id = $("#products-wrapper").data("party-id");
      user_id = $("#products-wrapper").data("user-id");
    }
    else {
      party_id = $("#host-product-search").data("party-id");
      user_id = $("#host-product-search").data("user-id");
    }
    const url = `/parties/${party_id}/host/orders/update_order_items?user_id=${user_id}`;
    const order = { user_id, currency };
    let order_items = this.get_order_items();
    let order_item = order_items.find((item) => item.product_id.toString() == product_id.toString() && item.special && item.applied_promotion_name == promotion_name);
    if(!order_item){
      order_item = { product_id, quantity, special };
    }else{
      order_item["quantity"] = parseInt(order_item["quantity"]) + quantity;
    }
    order_items.push(order_item);
    let response = await post(url, { responseKind: "turbo-stream", body: { order: { ...order, order_items }, order_id, promotion_id} });
    $("#product-search-filter").val("");
    if(response.ok){
      window.showNotification('success', window.order_item_added_success_message);
    } else {
      this.response_message(response);
    }
    setTimeout(this.close_product_add_disable_popup, 1000);
  }

  hideGuestPayments(){
    if($("#guestPaymentMethods")){
      console.log("sss", $("#guestPaymentMethods"));
      console.log("ffff", $("#guest-order-final-total-value").val(), $("#guest-order-final-total-value"));
      if($("#guest-order-final-total-value") && parseFloat($("#guest-order-final-total-value").val()) <= 0){
        $("#guestPaymentMethods").attr('style','display: none !important');
        $("#guestCheckout").hide();
      }
    }
  }

  async add_host_product_detail(event) {
    let dataset = this.goToElementDataset(event, "BUTTON");
    const quantity = $("#product-detail-quantity").val();
    await this.add_host_product_item(dataset, parseInt(quantity));
  }

  async add_host_product(event) {
    let dataset = this.goToElementDataset(event, "BUTTON");
    await this.add_host_product_item(dataset, 1);
  }

  async change_product_quantity(event) {
    let dataset = this.goToElementDataset(event, "IMG");
    const order_id = dataset.orderId;
    const order_item_id = dataset.orderItemId;
    const product_id = dataset.productId;
    const operation = dataset.operation;
    const order_type = dataset.orderType;
    let promotion_id;
    if($("#selected_promotion_id")) {
      promotion_id = $("#selected_promotion_id").val();
    }
    let quantity = parseInt($(`#order-item-quantity-${product_id}-${order_item_id || ""}`).val());
    if(operation === "Add") {
      quantity = quantity + 1;
    } else {
      quantity = quantity - 1;
    }
    let party_id, user_id;
    if($("#products-wrapper").data("party-id")){
      party_id = $("#products-wrapper").data("party-id");
      user_id = $("#products-wrapper").data("user-id");
    }
    else {
      party_id = $("#host-product-search").data("party-id");
      user_id = $("#host-product-search").data("user-id");
    }
    const url = `/parties/${party_id}${order_type == "host" ? "/host" : ""}/orders/update_order_items?user_id=${user_id}`;
    const order = this.get_order_params();
    let order_items = this.get_order_items();
    let order_item = order_items.find((item) => item.product_id.toString() == product_id.toString() && (!order_item_id || item.order_item_id.toString() == order_item_id.toString()));
    if(quantity > 0) {
      order_item["quantity"] = quantity;
    } else {
      order_items = order_items.filter((item) => item.product_id.toString() != product_id.toString() || (order_item_id && item.order_item_id.toString() != order_item_id.toString()));
    }
    let response = await post(url, { responseKind: "turbo-stream", body: { order: { ...order, order_items }, order_id, promotion_id } });
    if(response.ok){
    } else {
      this.response_message(response);
    }
    setTimeout(this.close_product_add_disable_popup, 500);
    setTimeout(this.hideGuestPayments, 500);
  }

  async remove_coupon(event){
    let dataSet;
    let element = event.target;
    while(true){
      if(element.classList.contains("remove-coupon-code")){
        dataSet = element.dataset;
        break;
      }
      element = element.parentElement;
    }
    
    const party_id = dataSet.partyId;
    const order_id = dataSet.orderId;
    let coupon_code = dataSet.couponCode;
    const order_type = "guest";
    const url = `/parties/${party_id}${order_type == "host" ? "/host" : ""}/orders/update_order_items`;
    const order = this.get_order_params();
    let order_items = this.get_order_items();
    let response = await post(url, { responseKind: "turbo-stream", body: { order: { ...order, order_items }, delete_coupon_code: coupon_code, order_id } });
    if(response.ok){
      window.showNotification('success', window.deleted_coupon_code);
    } else {
      this.response_message(response);
    }
    setTimeout(this.close_product_add_disable_popup, 500);
  }

  async apply_coupon_code(event) {
    let coupon_code = $("#apply_coupon_code").val();
    if(coupon_code){
      let dataSet;
      let element = event.target;
      while(true){
        if(element.classList.contains("apply_coupon")){
          dataSet = element.dataset;
          break;
        }
        element = element.parentElement;
      }

      const party_id = dataSet.partyId;
      const order_id = dataSet.orderId;
      const order_type = "guest";
      
      const url = `/parties/${party_id}${order_type == "host" ? "/host" : ""}/orders/update_order_items`;
      const order = this.get_order_params();
      let order_items = this.get_order_items();
      let response = await post(url, { responseKind: "turbo-stream", body: { order: { ...order, order_items }, coupon_code, order_id } });
      if(response.ok){
        $("#apply_coupon_code").val("");
        window.showNotification('success', window.coupon_code_applied);
      } else {
        this.response_message(response);
      }
    } else {
      window.showNotification('error', window.invalid_coupon_code);
    }
    setTimeout(this.close_product_add_disable_popup, 500);
  }

  async delete_order(event) {
    if(confirm(window.order_delete_confirmation_dialog)){
      let dataset = this.goToElementDataset(event, "IMG");
      const order_id = dataset.orderId;
      const party_id = dataset.partyId;
      const url = `/parties/${party_id}/orders/${order_id}`;
      let response = await destroy(url, { responseKind: "turbo-stream" });
      if(response.ok){
        window.showNotification('success', window.order_delete_success_message);
      } else {
        this.response_message(response);
      }
    }
  }

  async reload_order_items(event) {
    let dataSet;
    let element = event.target;
    while(true){
      if(element.classList.contains("host-promotion-card") || element.classList.contains("host-promotion-card-1")){
        dataSet = element.dataset;
        break;
      }
      element = element.parentElement;
    }
    const order_id = dataSet.orderId;
    const order_type = dataSet.orderType;
    const promotion_id = dataSet.promotionId;
    let party_id, user_id;
    if($("#products-wrapper").data("party-id")){
      party_id = $("#products-wrapper").data("party-id");
      user_id = $("#products-wrapper").data("user-id");
    }
    else {
      party_id = $("#host-product-search").data("party-id");
      user_id = $("#host-product-search").data("user-id");
    }
    const url = `/parties/${party_id}${order_type == "host" ? "/host" : ""}/orders/reload_order_items?user_id=${user_id}`;
    await post(url, { responseKind: "turbo-stream", body: { order_id, promotion_id } });
  }

  async remove_product(event) {
    let dataset = this.goToElementDataset(event, "SPAN");
    const product_id = dataset.productId;
    const order_id = dataset.orderId;
    const order_item_id = dataset.orderItemId;
    const order_type = dataset.orderType;
    let promotion_id;
    if($("#selected_promotion_id")) {
      promotion_id = $("#selected_promotion_id").val();
    }
    let party_id, user_id;
    if($("#products-wrapper").data("party-id")){
      party_id = $("#products-wrapper").data("party-id");
      user_id = $("#products-wrapper").data("user-id");
    }
    else {
      party_id = $("#host-product-search").data("party-id");
      user_id = $("#host-product-search").data("user-id");
    }
    const url = `/parties/${party_id}${order_type == "host" ? "/host" : ""}/orders/update_order_items?user_id=${user_id}`;
    const order = this.get_order_params();
    let order_items = this.get_order_items(product_id, order_item_id);
    let response = await post(url, { responseKind: "turbo-stream", body: { order: { ...order, order_items }, order_id, promotion_id } });
    if(response.ok){
      window.showNotification('success', window.order_item_removed_success_message);
    } else {
      this.response_message(response);
    }
    setTimeout(this.close_product_add_disable_popup, 500);
    setTimeout(this.hideGuestPayments, 500);
  }

  add_host(event) {
    const frame = document.getElementById('host_form');
    var user_id = event.target.dataset.userId;
    if (user_id == undefined) {
      user_id = $(event.target).parents('.btn-gst').data('userId');
    }
    window.party_host_id = user_id;
    const editable = event.target.dataset.editable;
    frame.src = '/users/' + user_id + '?editable=' + editable;
    frame.reload();
    $("#host_search_input").val("");
    $('#users').html('');    
    if (user_id != 'new_user') {
      const users_frame = document.getElementById('users');
      users_frame.src = "/users?selected_user_id=" + user_id + "&user_type=host";
      users_frame.reload();
      window.showNotification('success', window.host_add_success_message);
    }
    frame.addEventListener('turbo:frame-load', () => {
      const select = frame.querySelector('select.country');
      if (select && event.target.id === "search_add_host") {
        select.dispatchEvent(new Event('change')); 
      }
    }, { once: true });
  }

  remove_host(event) {
    const frame = document.getElementById('host_form');
    frame.src = '/users/new_user?editable=1';
    frame.reload();
    $("#host_search_input").val("");
    $('#users').html('');
    frame.addEventListener('turbo:frame-load', () => {
      const select = frame.querySelector('select.country');
      if (select) select.dispatchEvent(new Event('change')); 
    }, { once: true });

    window.showNotification('success', window.host_remove_success_message);
    window.party_host_id = "";
  }

  async add_guest(event) {
    const party_id = $("#guests-wrapper").data("party-id")
    var user_id = event.target.dataset.userId;
    if (user_id == undefined) {
      user_id = $(event.target).parents('.btn-gst').data('userId');
    }
    const url = '/parties/' + party_id + '/add_guest?user_id=' + user_id;
    await post(url, { responseKind: "turbo-stream" })
    $("#search-filter").val("");
    $("#users tbody").html("");
    window.showNotification('success', window.guest_added_success_message)
  }

  async edit_guest(event) {
    const party_id = $("#guests-wrapper").data("party-id")
    const user_id = event.target.dataset.userId;
    const url = '/parties/' + party_id + '/edit_guest?user_id=' + user_id;
    await post(url, { responseKind: "turbo-stream" })
    $("#newGuestForm").show();
  }  

  async remove_guest(event) {
    const party_id = $("#guests-list").data("party-id")
    const user_id = event.target.dataset.userId;
    const url = '/parties/' + party_id + '/remove_guest?user_id=' + user_id;
    await post(url, { responseKind: "turbo-stream" })
    window.showNotification('success',  window.guest_removed_success_message)
  }  
}
