import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  connect() {
    if (this.element.options.length == 2 && !this.element.closest('#host_form')) {
      this.change({target: this.element});
    }
  }

  change(event) {
    const target = event.target.getAttribute("target");
    const target_input_name = event.target.getAttribute("target_input_name");
    const frame = document.getElementById(target).firstElementChild;
    console.log(document.getElementById(target));
    frame.src = '/states?country_id=' + event.target.value + "&input_name=" + target_input_name;
    frame.reload();
    //const tel_target = event.target.getAttribute("tel_target");
    // if (tel_target) {
    //   var contact = document.querySelector(tel_target);
    //   if (contact.value == "") {
    //     var iti = window.intlTelInputGlobals.getInstance(contact);
    //     iti.setCountry(window.countryISOMap[parseInt(event.target.value)]);
    //   }
    // }
  }

}
